<template>
	<div ref='concierge' @keydown.esc="closeConcierge" tabindex='0'>
		<transition name="translate-bottom">
			<div class="app-concierge" v-if='value'>
				<div class="app-concierge__inner">
					<div class='app-container app-container-fluid a-container'>
						<div class='a-booking-container'>
							<div class='a-booking-content' v-click-outside="closeConcierge">
								<div class='app-concierge-mobile-header mobile-show'>
									<span class='app-concierge-mobile-header-item'>
										{{$t('account.home.travel_concierge.title')}}
										<v-icon icon='crown' size='18' />
									</span>
									<div class="app-concierge-mobile-header-close" @click="closeConcierge">
										<v-icon icon="cross" size="17"></v-icon>
									</div>
								</div>
								<div class='a-booking-nav'>
									<div class='a-booking-nav__header mobile-hide'>
										<div class='a-booking-nav__header__container'>
											<div class='a-booking-nav__header-item router-link-active'>
												<span class='a-booking-nav__header-item-content'>
													{{$t('account.home.travel_concierge.title')}}
													<v-icon icon='crown' size='18' />
												</span>
											</div>
										</div>
									</div>
									<div class='a-booking-box'>
										<form>
											<div class='a-booking-form-row'>
												<div class="a-booking-form-col">
													<p class='app-concierge-label'>{{$t('account.home.travel_concierge.when_does_your_trip_start')}}</p>
													<a-date-picker
														v-model='date'
														@change='changeDate($event)'
														:errors='!date && errors.date'
														:placeholder="$t('account.home.travel_concierge.the_start_date_of_the_trip')"
														class='mobile-hide'
													/>
													<a-date-picker-mobile
														v-model='date'
														@change='changeDate($event)'
														:errors='!date && errors.date'
														:placeholder="$t('account.home.travel_concierge.the_start_date_of_the_trip')"
														class='mobile-show'
													/>
												</div>
												<div class="a-booking-form-col a-booking-form-col-12 mb-0">
													<p class='app-concierge-label'>{{$t('account.home.travel_concierge.what_service_do_you_need')}}</p>
													<v-text-field
														:placeholder="$t('account.home.travel_concierge.write_your_request_here')"
														name='textarea'
														type='textarea'
														v-model='form.text'
														:rows='5'
														:errorMessage='!form.text && errors.text ? errors.text[0] : null'
													/>
												</div>
												<div class="a-booking-form-col a-booking-form-col-12 app-concierge-file-upload">
													<v-file-upload
														:title="$t('account.home.travel_concierge.fileUploadTitle')"
														:maxSize='5000'
														:hint="$t('account.home.travel_concierge.fileUploadHint')"
														accept=".pdf,application/pdf,.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
														@update-files='form.file = $event'
													/>
												</div>
											</div>

											<div class='row a-booking-form-action'>
												<div class="col"></div>
												<div class="col-auto">
													<v-button small color='green white--text app-concierge-btn' :loading='loading_btn' @click='TravelConcierge'>{{$t('account.buttons.send_a_request')}}</v-button>
												</div>
											</div>
										</form>

										<div class="app-popup__close mobile-hide" @click="closeConcierge">
											<v-icon icon="cross" size="17"></v-icon>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</transition>
		<div class="backdrop" v-if='value'></div>
	</div>
</template>

<script>
	import { disablePageScroll, enablePageScroll } from 'scroll-lock';
	import VFileUpload from '@/components/VFileUpload';
	import { TravelConcierge } from '@/services/request';

	export default {
		data: () => ({
			form: {
				date: null,
				text: "",
				file: ""
			},
			date: null,
			loading_btn: false,
			errors: {}
		}),
		props: {
			value: {
				type: Boolean,
				default: false
			}
		},
		components: {
			VFileUpload
		},
		methods: {
			TravelConcierge() {
				this.loading_btn = true;

				TravelConcierge(this.form)
					.then(() => this.$emit("success-concierge"))
					.catch(err => this.errors = err.response.data?.errors ?? {})
					.finally(() => this.loading_btn = false);
			},
			changeDate($event) {
				this.date = $event
				this.form.date = this.$moment($event).format('YYYY-MM-DD');
			},
			is_touch_enabled() {
				return ( 'ontouchstart' in window ) || ( navigator.maxTouchPoints > 0 ) || ( navigator.msMaxTouchPoints > 0 );
			},
			pageScroll() {
				const hideEl = document.querySelector('.app-concierge')
				this.value ? disablePageScroll(hideEl) : enablePageScroll(hideEl)
			},
			closeConcierge() {
				this.$emit('close-concierge');
			}
		},
		watch: {
			value() {
				if(!this.value) {
					this.form = {
						date: null,
						text: "",
						file: ""
					};
					this.date = null;
					this.loading_btn = false;
					this.errors = {};
				}
				this.is_touch_enabled() ? setTimeout(() => { this.pageScroll() }, 150) : this.pageScroll();
			}
		}
	}
</script>

<style lang="scss">
	@import "@/styles/global/variables";
	@import "@/styles/global/mixins";

	.app-concierge {
		position: fixed;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: 12;
		overflow-x: hidden;
		overflow-y: scroll;
		&__inner {
			width: 100%;
			min-height: 100%;
			display: grid;
			align-items: center;
			@include minw( $grid-breakpoints-xs ) {
				padding-bottom: 50px;
			}
		}
		&__close {
			position: absolute;
			right: 25px;
			top: 25px;
			cursor: pointer;
			z-index: 1;
			svg path {
				transition: fill $transition;
			}
			&:hover {
				svg path {
					fill: $green;
				}
			}
		}
		&-label {
			font-size: 18px;
			font-weight: 700;
			margin-top: 30px;
			margin-bottom: 15px;
			@include minw( $grid-breakpoints-xs ) {
				margin-top: 25px;
			}
		}
		.a-booking {
			&-nav {
				&__header {
					justify-content: flex-start;
					&-item:before {
						border-radius: 5px 5px 0 0;
					}
					.app-icon {
						margin-left: 10px;
					}
				}
			}
			&-container {
				@include maxw( $grid-breakpoints-xs - 1 ) {
					padding-top: 0;
				}
			}
			&-box {
				@include maxw( $grid-breakpoints-xs - 1 ) {
					border-radius: 0;
					min-height: 100vh;
					display: grid;
					padding-top: 70px;
					padding-left: 20px;
					padding-right: 20px;
				}
			}
		}
		.app-container {
			@include maxw( $grid-breakpoints-xs - 1 ) {
				padding: 0;
			}
		}
		&-mobile-header {
			position: fixed;
			top: 0;
			left: 0;
			right: 0;
			border-bottom: 1px solid rgba($black, .1);
			padding: 20px;
			background-color: $white;
			z-index: 5;
			display: flex;
			align-items: center;
			justify-content: space-between;
			&-item {
				display: flex;
				align-items: center;
				font-size: 16px;
				font-weight: 700;
				color: $green-persian;
				.app-icon {
					margin-left: 10px;
					flex-shrink: 0;
				}
			}
			&-close {
				flex-shrink: 0;
				margin-left: 20px;
				color: rgba($black, .5);
			}
		}
		&-dialog {
			.app-popup__close {
				@include maxw( $grid-breakpoints-xs - 1 ) {
					display: none !important;
				}
			}
		}
		&-file-upload {
			margin-top: 4px;
		}
		&-btn {
			min-width: 225px;
		}
	}
</style>
