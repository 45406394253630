<template>
	<div class='a-booking-nav'>
		<div class='a-booking-nav__header'>
			<div class='a-booking-nav__header__container'>
				<router-link v-for='(item, index) in updateItems.slice(0, count)'
					:key='index'
					:to="item.link ? $i18nRoute({ name: item.link }) : '#'"
					exact
					class='a-booking-nav__header-item'
					:class='{disabled: !item.link}'
				>
					<span class='a-booking-nav__header-item-content'>
						<template v-if='mobile'>
							<v-icon :class='{stroke: item.name == "flights" || item.name == "charter-flight"}' :icon='"navigation/" + item.name' size='24' />
						</template>
						<template v-else>
							{{ $t(`account.booking.tabs.title.${item.name}`)}}
						</template>
					</span>
				</router-link>
				<template v-if='updateItems.length > count'>
					<div class='a-booking-nav__header-dropdown'
						:class='{"router-link-active": updateItems.slice(count).find(el => el.link === $route.name)}'
					>
						<span class='a-booking-nav__header-item-content'>
							{{$t('account.booking.tabs.title.more')}}
						</span>
						<div class='a-booking-nav__header-dropdown-list'>
							<div class='a-booking-nav__header-dropdown-item default'>
								{{$t('account.booking.tabs.title.more')}}
							</div>
							<router-link v-for='(item, index) in updateItems.slice(count)'
								:key='index'
								:to="item.link ? $i18nRoute({ name: item.link }) : '#'"
								exact
								class='a-booking-nav__header-dropdown-item'
								:class='{"disabled": !item.link}'
							>
								{{ $t(`account.booking.tabs.title.${item.name}`)}}
							</router-link>
						</div>
					</div>
				</template>
			</div>
		</div>
		<slot />
	</div>
</template>

<script>
	const initialCount = 6;
	const initialInnerWidth = 960;

	export default {
		data: () => ({
			count: initialCount,
			mobile: false
		}),
		props: ["items"],
		computed: {
			updateItems() {
				if(this.items.findIndex(el => el.link === this.$route.name) + 1 >= this.count) {
					let newItems = this.items.slice(0, this.count - 1);
							newItems.push(this.items.find(el => el.link === this.$route.name));
							newItems.push(...this.items.slice(this.count - 1).filter(el => el.link !== this.$route.name));
					return newItems;
				} else {
					return this.items;
				}
			}
		},
		created() {
			this.count = window.innerWidth >= initialInnerWidth ? initialCount : this.updateItems.length;
			this.mobile = !(window.innerWidth >= initialInnerWidth);

			window.addEventListener(`resize`, () => {
				this.count = window.innerWidth >= initialInnerWidth ? initialCount : this.updateItems.length;
				this.mobile = !(window.innerWidth >= initialInnerWidth);
			}, false);
		}
	}
</script>

<style lang="scss">
	@import "@/styles/global/variables.scss";
	@import "@/styles/global/mixins";

	.a-booking-nav {
		&__header {
			display: grid;
			margin: 0 auto;
			width: 100%;
			padding: 0 20px * .8;
			max-width: 1000px * .8;
			@include minw( $grid-breakpoints-md ) {
				padding: 0 20px * .9;
				max-width: 1000px * .9;
			}
			@include minw( $grid-breakpoints-lg ) {
				padding: 0 20px;
				max-width: 1000px;
			}
			&__container {
				display: flex;
				justify-content: space-between;
				background-color: #D1F2EC;
				border-radius: 5px 5px 0 0;
				@include maxw( $grid-breakpoints-sm - 1 ) {
					overflow-y: hidden;
					overflow-x: auto;
					flex-wrap: nowrap;
				}
			}
			&-item,
			&-dropdown {
				font-size: 14px;
				font-weight: 700;
				color: rgba($black, .9);
				padding: 8px 20px;
				min-height: 56px;
				min-width: 56px;
				cursor: pointer;
				position: relative;
				display: flex;
				align-items: center;
				justify-content: center;
				flex: 1 0 auto;
				@include minw( $grid-breakpoints-sm ) {
					min-height: 56px * .8;
					padding: 8px * .6 25px * .5;
				}
				@include minw( $grid-breakpoints-md ) {
					font-size: 16px * .9;
					padding: 8px * .9 25px * .9;
					min-height: 56px * .9;
				}
				@include minw( $grid-breakpoints-lg ) {
					font-size: 16px;
					padding: 8px 25px;
					min-height: 56px;
				}
				&:before {
					content: '';
					position: absolute;
					top: 0;
					right: 0;
					bottom: 0;
					left: 0;
					z-index: 1;
				}
				&:first-child {
					&:before {
						border-radius: 5px 0 0 0;
					}
				}
				&:last-child {
					&:before {
						border-radius: 0 5px 0 0;
					}
				}
				&:hover {
					color: $green-persian;
				}
				&.router-link-active {
					color: $green-persian;
					&:before {
						background-color: $white;
					}
				}
				&-content {
					display: flex;
					align-items: center;
					position: relative;
					z-index: 1;
					.app-icon {
						&.stroke {
							svg {
								fill: none;
								stroke: currentColor;
							}
						}
					}
				}
			}
			&-item {
				&.disabled {
					pointer-events: none;
					opacity: .3;
				}
			}
			&-dropdown {
				&:hover {
					.a-booking-nav__header-dropdown-list {
						opacity: 1;
						visibility: visible;
					}
				}
				&-list {
					position: absolute;
					top: 0;
					right: 0;
					width: 255px * .8;
					z-index: 2;
					background: $white;
					box-shadow: 0px 4px 30px rgba(86, 86, 86, 0.2);
					border-radius: 0 10px 10px 10px;
					padding: 5px * .8 0;
					overflow: hidden;
					opacity: 0;
					visibility: hidden;
					transition: opacity $transition, visibility $transition;
					@include minw( $grid-breakpoints-md ) {
						width: 255px * .9;
						padding: 5px * .9 0;
						left: 0;
						right: auto;
					}
					@include minw( $grid-breakpoints-lg ) {
						width: 255px;
						padding: 5px 0;
					}
				}
				&-item {
					padding: 14px * .7 30px * .5;
					color: $black;
					display: block;
					text-align: right;
					@include minw( $grid-breakpoints-md ) {
						padding: 14px * .9 30px * .9;
						text-align: left;
					}
					@include minw( $grid-breakpoints-lg ) {
						padding: 14px 30px;
					}
					&.default {
						cursor: default;
					}
					&.disabled {
						pointer-events: none;
						opacity: .3;
					}
					&:not(.default) {
						&:hover,
						&.router-link-active {
							background-color: $azure;
						}
						&.router-link-active {
							color: $green-persian;
						}
					}
				}
			}
		}
	}
</style>
