<template>
	<div>
		<div class='a-booking'>
			<div class='app-container app-container-fluid a-container'>
				<div class='text-center a-booking-header'>
					<p class='a-booking-user'>{{$t('account.booking.welcome')}}{{$store.state.auth.user ? `, ${$store.state.auth.user.name}` : ""}}!</p>
					<p class='a-booking-title'>{{$t('account.booking.title')}}</p>
				</div>
				<div class='a-booking-container'>
					<div class='a-booking-content'>
						<a-booking-nav :items='links'>
							<div class='a-booking-box'>
								<div class='mobile-show'>
									<p class='a-booking-form-title'>{{$t(`routes.${this.$route.name}`)}}</p>
								</div>

								<router-view />
							</div>
						</a-booking-nav>
					</div>
					<div class="a-booking-banner"></div>

					<a-social-button
                        @open-concierge='concierge = $event'
                        @open-subscribe='subscribe = $event'
                    />
				</div>
			</div>
		</div>

		<a-travel-concierge
			v-model='concierge'
			@close-concierge="concierge = false"
			@success-concierge="concierge = false, conciergeSuccess = true"
		/>
<!--        <a-subscribe-->
<!--			v-model='subscribe'-->
<!--			@close-concierge="subscribe = false"-->
<!--			@success-concierge="subscribe = false, subscribeSuccess = true"-->
<!--		/>-->

		<dialog-success
			:title="$t('account.messages.success.your_request_has_been_sent_successfully')"
			:text="$t('account.messages.success.our_manager_will_contact_you_in_the_near_future')"
			class='app-concierge-dialog'
			v-model='conciergeSuccess'
			@close-dialog="conciergeSuccess = false"
		>
			<template v-slot:default>
				<div class='app-concierge-mobile-header mobile-show'>
					<span class='app-concierge-mobile-header-item'>
						{{$t('account.home.travel_concierge.title')}}
						<v-icon icon='crown' size='18' />
					</span>
					<div class="app-concierge-mobile-header-close" @click="$emit('close-dialog')">
						<v-icon icon="cross" size="17"></v-icon>
					</div>
				</div>
			</template>
		</dialog-success>
	</div>
</template>

<script>
	import ABookingNav from '@/views/account/home/components/ABookingNav';
	import ASocialButton from '@/views/account/home/components/ASocialButton';
	// import ASubscribe from '@/views/account/home/subscribe';
	import ATravelConcierge from '@/views/account/home/travel_concierge';
	import DialogSuccess from '@/views/account/home/components/DialogSuccess';

	export default {
		data: () => ({
			links: [
				// {link: "flights", name: "flights"},
				{link: "hotels", name: "hotels"},
				{link: "transfers", name: "transfers"},
				// {link: "cars", name: "cars"},
                {link: "charter-flight", name: "charter-flight"},
                {link: "insurance", name: "insurance"},
                {link: "groupmaker", name: "groupmaker"},
				// {link: "", name: "theme_parks"},
				// {link: "", name: "tours"},
				// {link: "", name: "cruises"},
				// {link: "", name: "excursions"}
			],
			concierge: false,
            subscribe: false,
			conciergeSuccess: false,
            subscribeSuccess: false
		}),
		components: {
            // ASubscribe,
			ABookingNav,
			ASocialButton,
			ATravelConcierge,
			DialogSuccess
		}
	}
</script>
