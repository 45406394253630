<template>
	<ul class='a-social-button-list'>
		<li class='a-social-button-item' v-for='(item, i) in items' :key='i'>
			<template v-if='item.action'>
				<span class='a-social-button-link' @click='item.action'>
					<v-icon :icon='item.icon' />
				</span>
			</template>
			<template v-else>
				<a class='a-social-button-link' :href='item.link' target="_blank">
					<v-icon :icon='item.icon' />
				</a>
			</template>
			<span class='a-social-button-tooltip'>{{item.tooltip_name}}</span>
		</li>
	</ul>
</template>

<script>
	export default {
		computed: {
			items() {
				return [
					{icon: "crown", action: this.openConcierge, tooltip_name: this.$t("account.booking.social.travel-concierge")},
					{icon: "telegram", link: "https://t.me/easytour_club", tooltip_name: 'Telegram'},
					// {icon: "viber", link: "https://invite.viber.com/?g2=AQAx9Aav4xY9p0x%2FzgMQEEXWIWrxo1viyll7H1pepLm1dWDjZkgshqGX5PTUP1m3", tooltip_name: 'Viber'}
					{icon: "facebook", link: "https://www.facebook.com/groups/easytour.club", tooltip_name: 'Facebook'},
					// {icon: "subscribe", action: this.openSubscribe, tooltip_name: 'Subscribe'}
				]
			}
		},
		methods: {
			openConcierge() {
				this.$emit('open-concierge', true)
			},
            openSubscribe() {
                this.$emit('open-subscribe', true)
            }
		}
	}
</script>

<style lang="scss">
	@import "@/styles/global/variables";
	@import "@/styles/global/mixins";

	.a-social-button {
		&-list {
			position: absolute;
			@include minw( $grid-breakpoints-sm ) {
				z-index: 5;
				bottom: 70px * .6;
				right: 100%;
			}
			@include minw( $grid-breakpoints-md ) {
				bottom: 70px * .9;
			}
			@include minw( $grid-breakpoints-lg ) {
				bottom: 70px;
			}
			@include maxw( $grid-breakpoints-sm - 1 ) {
				left: 0;
				right: 0;
				margin-top: 35px;
				display: flex;
				align-items: center;
				justify-content: center;
			}
		}
		&-item {
			margin-right: 14px;
			@include minw( $grid-breakpoints-sm ) {
				margin-right: 30px * .2;
				margin-bottom: 12px * .6;
			}
			@include minw( $grid-breakpoints-md ) {
				margin-right: 30px * .4;
			}
			@include minw( $grid-breakpoints-lg ) {
				margin-right: 30px;
				margin-bottom: 12px;
			}
		}
		&-link {
			width: 52px;
			height: 52px;
			display: flex;
			align-items: center;
			justify-content: center;
			border-radius: 50%;
			border: 1px solid rgba($green-persian, .1);
			transition: border-color $transition;
			cursor: pointer;
			@include minw( $grid-breakpoints-sm ) {
				width: 52px * .7;
				height: 52px * .7;
			}
			@include minw( $grid-breakpoints-md ) {
				width: 52px * .9;
				height: 52px * .9;
			}
			@include minw( $grid-breakpoints-lg ) {
				width: 52px;
				height: 52px;
			}
			.app-icon {
				color: $green-persian;
				width: 28px !important;
				height: 28px !important;
				@include minw( $grid-breakpoints-sm ) {
					width: 28px * .7 !important;
					height: 28px * .7 !important;
				}
				@include minw( $grid-breakpoints-md ) {
					width: 28px * .9 !important;
					height: 28px * .9 !important;
				}
				@include minw( $grid-breakpoints-lg ) {
					width: 28px !important;
					height: 28px !important;
				}
			}
			&:hover {
				border-color: $green-persian;
				& + .a-social-button-tooltip {
					opacity: 1;
					visibility: visible;
				}
			}
		}
		&-tooltip {
			display: none;
			@include minw( $grid-breakpoints-sm ) {
				position: absolute;
				top: 0;
				bottom: 0;
				left: 100%;
				display: flex;
				align-items: center;
				margin-left: 10px * .8;
				background: $white;
				box-shadow: 0px 0px 30px rgba(86, 86, 86, 0.2);
				border-radius: $border-radius * 2;
				font-size: 16px * .8;
				font-weight: 700;
				padding: 14px * .8 17px * .8;
				white-space: nowrap;
				opacity: 0;
				visibility: hidden;
				cursor: default;
				transition: opacity $transition, visibility $transition;
			}
			@include minw( $grid-breakpoints-md ) {
				margin-left: 10px * .9;
				font-size: 16px * .9;
				padding: 14px * .9 17px * .9;
			}
			@include minw( $grid-breakpoints-lg ) {
				margin-left: 10px;
				font-size: 16px;
				padding: 14px 17px;
			}
		}
	}
</style>
